import React from "react"
import { Card, Badge, Text, LegacyStack, RadioButton, Icon } from "@shopify/polaris"
import styled from "styled-components"
const GreyCard = styled.div`
  position: relative;
  .card-container {
    position: relative;
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #d8d8d8;
      border-radius: 0.8rem;
      z-index: 10;
      opacity: 0.4;
    }
  }
  .upsell-badge {
    text-align: center;
    margin-top: 20px;
    .Polaris-Badge {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
`
const OfferTypeEdit = styled.div`
    margin: auto;
    .Polaris-Icon {
      width: 50px;
      height: 50px;
    }
`           

const OnBoardingFeatureCard = (props) => {
  const {
    title,
    content,
    image,
    type,
    icon,
    url,
    selectedCard,
    handleCardChange,
    grey = false,
    loading = false,
    plusOnly = false,
    learnMore = false,
  } = props
  return grey ? (
    <Card>
    <div
      style={{
        height: "210px",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          height: "125px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={image} width={"100%"} />
      </div>
        <div>
        <div>
          <Text variant="headingSm" as="h3">
            <Text variant="bodyMd" as="span" fontWeight="semibold">
              {title}
            </Text>
          </Text>
        </div>
        <p>{content}</p>
        {plusOnly && (
        <div style={{textAlign:'center', paddingTop:'10px'}}>
          <Badge tone="info">SHOPIFY PLUS</Badge>
        </div>
      )}
        </div>
    </div>
  </Card>
  ) : (
    <Card>
      <div
        style={"Cart upsell" === type ? {
          height: "210px",
          position: "relative",
        }:{
          height: "210px",
          position: "relative",
        }}
      >
        <div
          style={"Cart upsell" === type ? 
            {textAlign: "center",
            height: "125px",
            display: "flex",
            alignItems: "center",
            background: "rgba(242, 245, 254, 1)",
            borderRadius: "0.75rem",
            marginBottom: "0.5rem",
            }
            :{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
           {"Cart upsell" === type ?
           <OfferTypeEdit>
            <Icon source={icon} tone="info"></Icon> 
           </OfferTypeEdit>
           :
          <img src={image} width={"100%"} />
           }
        </div>
        <LegacyStack alignment="leading" spacing="extraTight" wrap={false} distribution="fill">
        <RadioButton
                              checked={selectedCard === title}
                              id={title}
                              name="cards"
                              onChange={() =>
                                handleCardChange(title)
                              }
                            />
          <div>
          <div>
            <Text variant="headingSm" as="h3">
              <Text variant="bodyMd" as="span" fontWeight="semibold">
                {title}
              </Text>
            </Text>
          </div>
          <p>{content}</p>
          
          </div>
        </LegacyStack>
      </div>
    </Card>
  )
}

export default OnBoardingFeatureCard
